<template>
  <div class="bg-white">
    <component :is="cpn"> </component>
  </div>
</template>

<script>
import Login from './components/Login.vue'
import Home from './components/Home.vue'
import 'element-ui/lib/theme-chalk/index.css'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'ConceptNewWsOrgLayout',
  components: { Login, Home },
  data () {
    return {
      cpn: Login
    }
  },
  computed: {
    ...mapState({
      user: state => state.concept.user,
      mode: state => state.concept.mode,
      ws: state => state.concept.ws
    })
  },
  mounted () {
    this.handleComponent()
  },
  watch: {
    '$route.query' (q) {
      this.handleComponent()
    }
  },
  methods: {
    handleComponent () {
      if (!this.user) {
        let query = {}
        query.page = 'login'
        query.i = moment().unix()
        this.$router.push({ query }).catch(() => { })
        this.cpn = Login
        return
      }
      if (this.$route.query.page) {
        let page = this.$route.query.page
        if (page === 'home') {
          this.cpn = Home
          return false
        }
      } else {
        this.cpn = Home
        return false
      }
    }
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #fafafa !important;
}
</style>